<template>
  <div class="p-4">
    <annotation-list></annotation-list>
  </div>
</template>
<script>
import AnnotationList from './AnnotationList.vue';

export default {
  components: {
    AnnotationList,
  },
};
</script>
