<template>
  <div
    class="loader mx-auto"
    :style="{
      'border-top': '5px solid ' + colors[color],
      width: width + 'px',
      height: height + 'px',
    }"
  />
</template>
<script>
export default {
  data: function() {
    return {
      colors: {
        primary: '#0d6efd',
        secondary: '#6c757d',
        success: '#28a745',
        warning: '#ffc107',
        danger: '#dc3545',
        info: '#17a2b8',
      },
    };
  },
  props: {
    color: {
      default: 'primary',
    },
    width: {
      default: 40,
    },
    height: {
      default: 40,
    },
  },
};
</script>
<style scoped>
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #0d6efd;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
