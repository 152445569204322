<template>
  <div>
    <span v-if="column.dataIndex === 'id'">
      {{ record.id }}
    </span>

    <span v-if="column.dataIndex === 'taskName'">
      {{ record.taskName }}
    </span>

    <span v-if="column.dataIndex === 'Organization'">
      {{ record.Organization }}
    </span>

    <span v-if="column.dataIndex === 'startTrainingTime'">
      {{ record.startTrainingTime }}
    </span>

    <span v-if="column.dataIndex === 'progress'">
      <spinner-component v-if="isProgressLoading" :width="20" :height="20">
      </spinner-component>
      <a-progress v-else type="circle" :percent="getProgress" :width="40" />
    </span>

    <span v-if="column.dataIndex === 'model_choice'">
      <a-select
        v-model:value="selectedModel"
        :options="modelChoices"
        style="width: 120px"
        placeholder="Select Model..."
        @change="handleModelUpdate"
      ></a-select>
    </span>

    <span v-if="column.dataIndex === 'assigned_labelers'">
      <span class="d-flex align-items-center">
        <a-select
          :loading="isLoadingLabelers"
          mode="multiple"
          v-model:value="selectedLabelers"
          style="width: 200px"
          placeholder="Select Labeler..."
          :max-tag-count="2"
          :options="options"
        ></a-select>

        <a-button
          type="primary"
          class="ml-3"
          @click="assignTask(record.id)"
          :disabled="isDisabled"
        >
          {{ buttonTitle }}
        </a-button>
      </span>
    </span>

    <span v-if="column.dataIndex === 'actions'">
      <span class="d-flex justify-content-between action-cell">
        <a-button type="primary" @click="$emit('autoAnnotate', record)">
          Auto Annotate
        </a-button>
        <a-button type="primary" @click="goToVideos(record)">
          Show Videos
        </a-button>
        <a-button type="primary" @click="$emit('startTraining', record)">
          Start Training
        </a-button>
        <a-popconfirm
          title="Are you sure you want to delete this record?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="$emit('removeAnnotation', record)"
        >
          <template #icon
            ><QuestionCircleOutlined style="color: red"
          /></template>
          <a-button type="danger">
            <template #icon>
              <DeleteOutlined />
            </template>
          </a-button>
        </a-popconfirm>
      </span>
    </span>
  </div>
</template>
<script>
import DropdownComponent from '../../../shared/Components/Dropdown.vue';
import SpinnerComponent from '../../../shared/Components/Spinner.vue';
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue';
import CircleProgress from 'vue3-circle-progress';
import { useToast } from 'vue-toastification';
import httpClient from '../../../../service/httpClient';
import modelChoices from '../../../../config/models-choice-config';
import TaskService from '../../../../services/tasks';

export default {
  components: {
    DropdownComponent,
    SpinnerComponent,
    CircleProgress,
    DeleteOutlined,
    QuestionCircleOutlined,
  },
  setup() {
    const toast = useToast();
    return { modelChoices, toast };
  },
  props: [
    'item',
    'column',
    'labelers',
    'taskLabelers',
    'assignedLabelers',
    'isLoadingLabelers',
    'progress',
    'isProgressLoading',
  ],
  emits: [
    'startTraining',
    'assignLabelersToTask',
    'removeAnnotation',
    'autoAnnotate',
    'updateList',
  ],
  data() {
    return {
      selectedLabelers: [],
      selectedModel: '',
      options: [],
      isCloningTask: false,
      record: {},
    };
  },
  mounted() {
    this.setOptions(this.labelers);
    this.selectedLabelers = this.assignedLabelers;
    this.record = this.item;
  },
  watch: {
    labelers(value) {
      this.setOptions(value);
    },
    assignedLabelers(labelers) {
      this.selectedLabelers = labelers;
    },
    record(value) {
      this.selectedModel = value.model_choice;
    },
    item(data) {
      this.record = data;
    },
  },
  computed: {
    isDisabled() {
      return (
        this.selectedLabelers.length === this.assignedLabelers.length &&
        this.selectedLabelers.every((e) => this.assignedLabelers.includes(e))
      );
    },
    buttonTitle() {
      return this.assignedLabelers.length === 0 ? 'Assign' : 'Update';
    },
    getProgress() {
      return Number(this.progress);
    },
  },
  methods: {
    assignTask(taskId) {
      this.$emit('assignLabelersToTask', {
        labelers: this.selectedLabelers,
        taskId: taskId,
      });
    },
    setOptions(labelers) {
      this.options = labelers.map((el) => ({
        value: el.id,
        label: el.username,
      }));
    },
    goToVideos({ id, taskName }) {
      let videosRoute = this.$router.resolve({
        name: 'Task Videos',
        params: { taskId: id, taskName },
      });
      window.open(videosRoute.href, '_blank');
    },
    async handleCloneTask(task_id) {
      this.isCloningTask = true;

      const successMessage =
        'Task cloning has started successfully, Please wait for a while.';
      const baseUrl = 'https://dev.retrocausal.tech/';
      await httpClient.post(
        'generic/clone_task/',
        { task_id },
        false,
        false,
        true,
        successMessage,
        '',
        baseUrl
      );
      this.isCloningTask = false;
    },
    async handleModelUpdate(value) {
      const payload = {
        model_choice: value,
      };
      const [error, _] = await TaskService.updateTask(
        this.record.id,
        payload,
        false
      );
      if (error) {
        this.toast.error('error occured in updating model');
        return;
      }
      this.$emit('updateList', {
        id: this.record.id,
        updatedData: payload,
      });
    },
  },
};
</script>
<style scoped>
button {
  margin: 0 4px;
}
</style>
