<template lang="">
  <div class="dropdown w-100" :key="selected" @click.stop="">
    <button
      :ref="id"
      @click.stop="handleOpen"
      class="btn btn-block btn-light d-flex"
      style="text-transform: none;"
      :disabled="disabled"
    >
      <small class="span-selected-items">{{
        selectedItem.join(', &nbsp;&nbsp;') || getPlaceHolder
      }}</small>
      <i v-if="open" class="ml-auto bi bi-caret-up-fill"></i>
      <i v-else class="ml-auto bi bi-caret-down-fill"></i>
    </button>
    <div class="dropdown-content" :class="{ show: open }">
      <input
        v-show="searchable"
        type="text"
        placeholder="Search.."
        class="myInput"
        v-model="searchQuery"
        @keyup="onKeyUp"
        ref="inputRef"
      />
      <div
        v-for="option in getOptions"
        :key="option.Serial_number"
        @click="onClick(option)"
        class="option"
      >
        <span>{{ option[item] }}</span>
        <input
          v-show="multiSelected"
          type="checkbox"
          class="ml-auto mr-2"
          :checked="selectedValue.includes(option[value])"
        />
      </div>
      <div v-if="getOptions.length === 0" class="option" disabled>
        No Item Found
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'id',
    'options',
    'item',
    'value',
    'searchable',
    'multiSelected',
    'selected',
    'disabled',
    'placeHolder',
  ],
  emits: ['change'],
  data: function() {
    return {
      open: false,
      selectedItem: [],
      selectedValue: [],
      searchQuery: '',
    };
  },
  watch: {
    selected: function() {
      this.updateValues();
    },
  },
  mounted() {
    this.updateValues();
    const self = this;
    window.addEventListener('click', () => {
      self.open = false;
    });
    this.emitter.on('close', () => {
      if (this.open) {
        this.open = false;
      }
    });
  },
  computed: {
    getOptions() {
      if (!this.options) {
        return [];
      }
      return this.options?.filter((el) =>
        el[this.item].toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    getPlaceHolder() {
      return this.placeHolder || 'Select';
    },
  },
  methods: {
    onClick(option) {
      const value = option[this.value];
      if (this.multiSelected) {
        if (this.selectedValue.includes(value)) {
          this.selectedValue.splice(this.selectedValue.indexOf(value), 1);
        } else {
          this.selectedValue.push(value);
        }
        this.$emit('change', JSON.stringify([...this.selectedValue]));
      } else {
        this.open = false;
        this.$emit('change', value);
      }
    },
    handleOpen() {
      if (!this.open) {
        this.emitter.emit('close');
      }
      this.$refs.inputRef.focus();
      this.open = !this.open;
      this.searchQuery = '';
    },
    updateValues() {
      if (this.multiSelected && this.selected) {
        const selectedOptions = this.options.filter((el) =>
          this.selected.includes(el[this.value])
        );
        this.selectedItem = selectedOptions.map((el) => el[this.item]);
        this.selectedValue = selectedOptions.map((el) => el[this.value]);
      } else {
        const selectedOption = this.options.find(
          (el) => el[this.value] === this.selected
        );

        this.selectedItem = selectedOption ? [selectedOption[this.item]] : [];
        this.selectedValue = selectedOption ? [selectedOption[this.value]] : [];
      }
    },
  },
};
</script>

<style scoped>
.span-selected-items {
  /*width: 120px;*/
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  margin-right: 10px;
  text-overflow: ellipsis;
}
.btn {
  border: 1px solid lightgray;
  height: 37.6px;
  align-items: center;
}

.myInput {
  width: 100%;
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ddd;
}

.myInput:focus {
  outline: 3px solid #ddd;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 150px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 10000;
  border-radius: 5px;
  margin-top: 5px;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: 0 0 5px 0 rgb(43 43 43 / 10%), 0 11px 6px -7px rgb(43 43 43 / 10%);
}

.option {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  border-bottom: 1px solid lightgray;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.option:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
</style>
